define("trivver-frontend/constants/campaign-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TMOD = _exports.PRODUCT_PORTAL = _exports.GAME = _exports.AR = _exports.ALL_TYPE = void 0;
  var GAME = "G";
  _exports.GAME = GAME;
  var PRODUCT_PORTAL = "P";
  _exports.PRODUCT_PORTAL = PRODUCT_PORTAL;
  var TMOD = "T";
  _exports.TMOD = TMOD;
  var ALL_TYPE = "T,P,A";
  _exports.ALL_TYPE = ALL_TYPE;
  var AR = "A";
  _exports.AR = AR;
});