define("trivver-frontend/pods/home/cabinet/advertiser/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({// breadCrumb: {
    //   title: "Client Dashboard",
    //   linkable: true,
    // },
  });

  _exports.default = _default;
});