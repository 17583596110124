define("trivver-frontend/pods/components/cancel-to-confirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aXyW9NNc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\",false],[12,\"class\",\"btn btn-grey btn-short\"],[12,\"type\",\"button\"],[3,\"action\",[[23,0,[]],[28,\"mut\",[[23,0,[\"open\"]]],null],true]],[8],[0,\"\\n  \"],[1,[23,0,[\"title\"]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"modals/no-yes\",[],[[\"@open\",\"@route\",\"@param\",\"@redirectLink\"],[[23,0,[\"open\"]],[23,0,[\"route\"]],[23,0,[\"param\"]],[29,[[23,0,[\"redirectLink\"]]]]]],{\"statements\":[[0,\"\\n  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "trivver-frontend/pods/components/cancel-to-confirm/template.hbs"
    }
  });

  _exports.default = _default;
});